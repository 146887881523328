<template>
  <div class="container">
    <div class="content">
      <section class="section inner-H">
        <div class="flow">
          <h2 class="headline">会員登録の流れ</h2>
          <img class="flow-step" src="/assets/img/account/flow-step03.png" />
          <img class="flow-bar" src="/assets/img/account/flow-bar03.png" />
        </div>
      </section>
      <section class="section inner-H inner-V bg-gray">
        <i class="icon icon-finish finish-icon"></i>
        <h2 class="headline headline-lg">会員登録が完了しました</h2>
        <p class="ta-c mb40">
          登録いただき誠にありがとうございます。<br />
          再度、ログインをしご利用ください。
        </p>
        <router-link class="btn btn-lg btn-main" :to="{ name: 'EKYCEntry' }">ログインする</router-link>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  name: 'EKYCEntryFinish',
  data: function() {
    return {
      //headerData
      pageName: '新規会員登録',
    };
  },
};
</script>
